
import React from 'react'
import { useStoreContext } from "../../contexts/StoreDataContext";
import styles from "./footer.module.css";
import Link from 'next/link';
import {mixpanel } from "../../services/helper";


export default function RegularFooter() {
    const storeData = useStoreContext();

    const navigateToHome = () => {

        const url = `https://dm2buy.com?utm_source=footer&utm_campaign=${storeData.subdomain}`;
        mixpanel.track('footer_cta', {
            "referrer": storeData.subdomain
        });
        window.open(url, "_blank").focus();
    };
    const legalInfo = storeData?.legalInfo;
    const terms = legalInfo?.terms
    const contacts = legalInfo?.contacts
    const returns = legalInfo?.returns
    const privacy = legalInfo?.privacy
    const about = legalInfo?.about
    const policy = legalInfo?.policy
    const care = legalInfo?.care
    const size = legalInfo?.size
    return (
        <footer className={styles.footer}>
            {/* <Image src="/instagram-4.png" width="24" height="24" /> */}
            <div className={styles.tagline} onClick={navigateToHome}>
                dm2buy
            </div>
            {
                legalInfo && (
                    <div className= {styles.regularQuickLink}> 
                        {policy && <Link href="/policy">Privacy Policy</Link>}
                    {care && <Link href="/care">Product Care</Link>}
                    {size && <Link href="/size">Size</Link>}
                    {terms && <Link href="/terms">Terms</Link>}
                    {returns && <Link href="/returns">Returns</Link>}
                    {privacy && <Link href="/privacy">Privacy</Link>}
                    {contacts && <Link href="/contact">Contact</Link>}
                    {about && <Link href="/about">About</Link>}

                    </div>
                )
            }
        </footer>
    );
}

