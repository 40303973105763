import styles from "./footer.module.css";
import Link from "next/link";
import { useStoreContext } from "../../contexts/StoreDataContext";
import { useContext, useMemo } from "react";
import Image from "next/image"
import { mixpanel } from "../../services/helper";
import FeaturedFooter from "./FeaturedFooter";
import RegularFooter from "./RegularFooter";

const Footer = () => {
  const storeData = useStoreContext();
  //console.log("))",storeData);
  const hideHeroMedia = storeData.hideHeroMedia
  const homePageEnabled = storeData.homePageEnabled
  const homeActive = useMemo(()=> {
    return homePageEnabled && homePageEnabled === true ? (hideHeroMedia ? false : true) : false
  },[hideHeroMedia,homePageEnabled]);
  if(homeActive) return <FeaturedFooter/>
  return <RegularFooter/>
}

export default Footer;
