import React, { forwardRef, useEffect } from "react";
import { useWindowVirtualizer } from "@tanstack/react-virtual";
import StoreItem from "../StoreItem";
import styles from "./virtualized-grid.module.css";

const VirtualizedGrid = forwardRef(
  ({ products, store, collectionsHeight }, ref) => {
    const parentRef = React.useRef(null);
    const [lastEl, setLastEl] = React.useState(null);

    useEffect(() => {
      const [lastEl] = products.slice(-1);
      setLastEl(lastEl.id);
    }, [products]);

    const rowVirtualizer = useWindowVirtualizer({
      count: Math.ceil(products.length / 2),
      scrollMargin: parentRef.current?.offsetTop ?? 0,
      // For ipad and bigger devices change the estimate size to 320 otherwise 266
      estimateSize: () => (screen.width > 768 ? 320 : 266),
      overscan: 2,
    });

    return (
      <div
        ref={parentRef}
        className="list"
        style={{
          paddingTop: `${collectionsHeight + 16}px`,
        }}
      >
        <div
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
          }}
          className={styles.virtualGrid}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow, index) => {
            const startIndex = virtualRow.index * 2;
            const endIndex = startIndex + 2;
            const rowProducts = products.slice(startIndex, endIndex);

            return (
              <div
                key={virtualRow.index}
                style={{
                  transform: `translateY(${
                    virtualRow.start - rowVirtualizer.options.scrollMargin
                  }px)`,
                }}
                className={styles.virtualRow}
              >
                <div className={styles.virtualRowInner}>
                  {rowProducts.map((product, index) => {
                    return (
                      <div ref={rowVirtualizer.measureElement}>
                        <div ref={lastEl === product.id ? ref : null}>
                          <StoreItem
                            key={product.id}
                            cohort={
                              store.cohort.includes(2) ? store.cohort : null
                            }
                            product={product}
                            pageSize={index + 20 > 20 ? index + 20 : 20}
                            left={index === 0}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);

export default VirtualizedGrid;
