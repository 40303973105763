import Head from "next/head";
import React from "react";
import styles from "../styles/Home.module.css";
import LoaderComponent from "../components/Loader";
import { getStore } from "../services/backend/serverSideProps";
import Error500Page from "./500";
import { MainHome } from "../components/Home/MainHome";
import StaticInfoBanner from "../components/Infobanner/StaticInfoBanner";

export async function getServerSideProps(context) {
  return getStore(context);
}

export default function Home(props) {
  const { storeData: store, storeUrl, error } = props;
  if(error && error.code === 500) return <Error500Page/>
  if(!store) return <LoaderComponent />
  return (
    <div className={styles.container}>
        <Head>
          <title>{store?.storeName}</title>
          <meta name="title" content={store?.storeName} />
          {store?.facebookDomainVerificationId ?
            <meta name="facebook-domain-verification" content={store?.facebookDomainVerificationId} /> : ''}
          <meta name="description" content={store?.storeBio} />
          <link rel="icon" href="/favicon.ico" />
          <link href="/fonts/fonts.css" rel="stylesheet" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=Lato:wght@700;900&family=Roboto:wght@400;700&display=swap"
            rel="stylesheet"
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=0"
          />

          <meta property="og:type" content="website" />
          <meta property="og:title" content={store?.storeName} />
          <meta
            property="og:description"
            content={store?.storeBio}
          />
          <meta
            property="og:image"
            content={'https:' + store?.storeLogo?.file?.url}
          />
          <meta property="og:site_name" content="dm2buy" />
          <meta property="og:url" content={storeUrl} />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:url" content={storeUrl} />
          <meta name="twitter:title" content={store?.storeName} />
          <meta
            property="twitter:description"
            content={store?.storeBio}
          />
          <meta
            property="twitter:image"
            content={'https:' + store?.storeLogo?.file?.url}
          />
        </Head>
        <StaticInfoBanner text={store?.storeBanner?.text}/>
        <MainHome store={store}/>
      </div>
  );
}